<template>
  <div class="writingReportPage-wrap">
    <div class="btnRow">
      <back-btn :handle-click="() => $router.push('/writing')" />
      <el-button @click="handledown2" type="primary" :loading="btnActive" style="margin-left: 20px"
        >导出PDF</el-button
      >
    </div>
    <div class="writingReportBox-outer">
      <div class="writingReportPage">
        <div id="writingReportBox">
          <img :src="require('@/assets/img/writing/logo.png')" alt="logo" class="logo" />
          <div class="contentWrapper">
            <div class="scoreSection">
              <img :src="require('@/assets/img/writing/scoreBg.png')" alt="bg" class="scoreBg" />
              <div class="scoreNum">{{ wrapNum(totalScore) }}</div>
              <div class="progressRow">
                <div class="textNum">0</div>
                <el-progress
                  class="progressItem"
                  color="#E0BE8F"
                  :show-text="false"
                  :stroke-width="20"
                  :percentage="(totalScore / 9) * 100"
                ></el-progress>
                <div class="textNum">9</div>
              </div>
            </div>
            <div class="margin6">
              <p class="labelText" v-for="(item, index) in baseInfoList" :key="`base${index}`">
                {{ item.label }}{{ item.value }}
              </p>
            </div>
            <p class="labelText bold"><span class="titleIcon"></span>作文评分</p>
            <div class="margin6">
              <p class="numDesc top">
                <span class="textNum">{{ wrapNum(scoreList[1]) }}</span>
                <span class="textDesc">Coherence & Cohesion</span>
              </p>
              <div class="middleRow">
                <p class="numDesc left">
                  <span class="textNum">{{ wrapNum(scoreList[0]) }}</span>
                  <span class="textDesc">Task Response</span>
                </p>
                <img
                  :src="require('@/assets/img/writing/radar-num.png')"
                  alt=""
                  class="scoreImg"
                  referer="no-referrer"
                />
                <p class="numDesc right">
                  <span class="textNum">{{ wrapNum(scoreList[2]) }}</span>
                  <span class="textDesc">Lexical Resource</span>
                </p>
              </div>
              <p class="numDesc bottom">
                <span class="textNum">{{ wrapNum(scoreList[3]) }}</span>
                <span class="textDesc">Grammatical Range & Accuracy</span>
              </p>
            </div>
            <div class="textContentItem margin6" v-for="item in list" :key="item.key">
              <p class="labelText bold"><span class="titleIcon"></span>{{ item.title }}</p>
              <div class="contentItem">
                <div class="bar"></div>
                <div class="textBox" v-if="item.type === 'richText'">
                  <div
                    v-html="item.content"
                    :class="{ 'comment-wrap': item.key === 'comment' }"
                  ></div>
                  <div v-for="(image, idx) in item.images" :key="idx">
                    <img
                      :src="image"
                      referrer="no-referrer|origin|unsafe-url"
                      crossorigin="anonymous"
                      alt=""
                      class="quesImage"
                    />
                  </div>
                </div>
                <div class="textBox" v-else-if="item.type === 'list'">
                  <div v-for="(it, index) in item.content" :key="index" class="subbox">
                    <div class="subtitle">{{ it.subtitle }}</div>
                    <div>{{ it.content }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="correctingSuggestions">
              <p class="labelText bold"><span class="titleIcon"></span>5、批改建议</p>
              <div class="message margin6">{{ correctingSuggestions }}</div>
            </div>
            <div class="bottomLine">
              <span class="line"></span>
              <img :src="require('@/assets/img/writing/line.png')" alt="" class="lineIcon" />
              <span class="line"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import BackBtn from "@/components/BackBtn.vue";
import { connectState } from "@/utils/wyUtil";

export default {
  name: "writingReport",
  components: { BackBtn },
  data() {
    return {
      correctingId: this.$route.params.id,
      totalScore: 0,
      scoreList: [0, 0, 0, 0],
      correctingSuggestions: "",
      btnActive: false
    };
  },
  created() {
    this.$store.dispatch("writing/getWriteInfo", this.correctingId);
  },
  watch: {
    writeInfo(val) {
      const {
        teacherTotalScore,
        teacherScore,
        operaterTotalScore,
        operaterScore,
        correctingSuggestions
      } = val || {};
      this.totalScore = operaterTotalScore || teacherTotalScore;
      this.scoreList = operaterScore
        ? operaterScore.split(",")
        : teacherScore
        ? teacherScore.split(",")
        : [0, 0, 0, 0];
      this.correctingSuggestions = correctingSuggestions;
    }
  },
  computed: {
    ...connectState("writing", ["writeInfo"]),
    baseInfoList() {
      const list = [];
      if (Object.keys(this.writeInfo).length) {
        const { correctingCode, nickName, targetWriteGrade } = this.writeInfo || {};
        correctingCode && list.push({ label: "作文编号 Code：", value: correctingCode });
        nickName && list.push({ label: "学生英文名 Name：", value: nickName });
        targetWriteGrade &&
          list.push({ label: "作文目标 Target ：", value: `${this.wrapNum(targetWriteGrade)}分` });
        return list;
      }
      return list;
    },
    list() {
      if (!Object.keys(this.writeInfo).length) {
        return [];
      }
      const {
        subjectContent,
        writingContent,
        correctingContent,
        operatorCorrectingContent,
        taskResponse,
        operatorTaskResponse,
        coherence,
        operatorCoherence,
        operatorGrammar,
        grammar,
        operatorAdvice,
        advice,
        subjectUrl
      } = this.writeInfo || {};

      return [
        {
          key: "question",
          title: "1、题目",
          type: "richText",
          content: subjectContent ? subjectContent.replace(/\r|\n|\t/g, "<br />") : "暂无数据",
          images: subjectUrl
        },
        {
          key: "answer",
          title: "2、学生作答",
          type: "richText",
          content: writingContent ? writingContent.replace(/\r|\n|\t/g, "<br />") : "暂无数据"
        },
        {
          key: "comment",
          title: "3、老师批改",
          type: "richText",
          // content: operatorCorrectingContent || correctingContent,
          content: operatorCorrectingContent
            ? operatorCorrectingContent.replace(/!important/g, "")
            : correctingContent
            ? correctingContent.replace(/!important/g, "")
            : ""
        },
        {
          key: "correct",
          title: "4、批改反馈",
          type: "list",
          content: [
            {
              subtitle: "· 写作任务回应",
              content: operatorTaskResponse || taskResponse
            },
            {
              subtitle: "· 连贯与衔接",
              content: operatorCoherence || coherence
            },
            {
              subtitle: "· 词汇与语法",
              content: operatorGrammar || grammar
            },
            {
              subtitle: "· 提升建议",
              content: operatorAdvice || advice
            }
          ]
        }
      ];
    }
  },
  methods: {
    wrapNum(num) {
      const str = num ? num.toString() : "0";
      return str.length > 1 ? str : `${str}.0`;
    },
    handledown() {
      this.btnActive = true;
      const timerId = setTimeout(() => {
        if (this.btnActive) {
          this.btnActive = false;
        }
        clearTimeout(timerId);
      }, 10000);
      const target = document.querySelector("#writingReportBox");
      const that = this;
      html2canvas(target, {
        scrollY: -document.documentElement.scrollTop,
        useCORS: true
      }).then(canvas => {
        const contentWidth = canvas.width;
        const contentHeight = canvas.height;

        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        const imgWidth = 520;
        const imgHeight = (520 / contentWidth) * contentHeight;
        const pageData = canvas.toDataURL("image/jpeg", 1.0);
        const pdf = new jsPDF("", "pt", [595.28, imgHeight]);
        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        // 当内容未超过pdf一页显示的范围，无需分页
        pdf.addImage(pageData, "JPEG", 37.64, 0, imgWidth, imgHeight);
        const { correctingCode, createDate } = that.writeInfo;
        pdf.save(`report_${correctingCode}_${createDate}.pdf`);
        that.btnActive = false;
      });
    },
    handledown2() {
      this.btnActive = true;
      const timerId = setTimeout(() => {
        if (this.btnActive) {
          this.btnActive = false;
        }
        clearTimeout(timerId);
      }, 10000);
      const target = document.querySelector("#writingReportBox");
      this.getDomToImageInfo(target).then(imageInfo => {
        const { correctingCode, createDate } = this.writeInfo;
        this.saveImageToPdf({ ...imageInfo, name: `report_${correctingCode}_${createDate}.pdf` });
        this.btnActive = false;
      });
    },

    getDomToImageInfo(target) {
      return new Promise(resolve => {
        domtoimage
          .toJpeg(target)
          .then(dataUrl => {
            const img = new Image();
            img.src = dataUrl;
            img.onload = () => {
              const imageInfo = {
                data: dataUrl,
                width: img.width,
                height: img.height
              };
              resolve(imageInfo);
            };
          })
          .catch(error => {
            console.error("oops, something went wrong!", error);
          });
      });
    },
    saveImageToPdf(imageInfo) {
      const contentWidth = imageInfo.width;
      const contentHeight = imageInfo.height;
      // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      const imgWidth = 520;
      const imgHeight = (520 / contentWidth) * contentHeight;
      const pageData = imageInfo.data;
      const pdf = new jsPDF("", "pt", [595.28, imgHeight]);
      // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
      // 当内容未超过pdf一页显示的范围，无需分页
      pdf.addImage(pageData, "JPEG", 37.64, 0, imgWidth, imgHeight);
      pdf.save(imageInfo.name);
    }
  }
};
</script>
<style lang="scss">
/*1rem=>24px;*/
.comment-wrap {
  /*调整教师评改的字体大小，统一大小*/
  * {
    font-size: 1.42rem !important;
    /*word-break: break-all;*/
    word-wrap: break-word;
    line-height: 2.5rem !important;
    height: auto !important;
  }
}

.writingReportPage-wrap {
  position: relative;
  .btnRow {
    width: 1160px;
    padding: 20px 100px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: flex;
    background-color: white;
  }
}

.writingReportBox-outer {
  background-color: white;
  height: calc(100vh - 64px);
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  .writingReportPage {
    width: 1160px;
    margin: 0 auto;
    background-color: white;
    #writingReportBox {
      width: 1160px;
      margin: 0 auto;
      background-color: white;
    }
  }
}

.writingReportPage {
  /*width: 100%;*/
  /*margin: auto;*/

  #writingReportBox {
    padding: 100px;
    width: 1160px;
    margin: 0 auto;
  }

  .logo {
    display: block;
    width: 6.21rem;
    height: 10.04rem;
    margin: 2.08rem auto;
  }
  .contentWrapper {
    width: 60rem;
    margin: auto;
  }
  .scoreSection {
    width: 100%;
    position: relative;
    margin-bottom: 3rem;
    .scoreBg {
      width: 60rem;
      height: 27.83rem;
      max-width: 100%;
    }
    .scoreNum {
      width: 10.58rem;
      height: 9.79rem;
      text-align: center;
      font-size: 6.79rem;
      line-height: 9.79rem;
      font-family: SourceHanSerifCN-Heavy, SourceHanSerifCN;
      position: absolute;
      left: 0;
      right: 0;
      top: 5.08rem;
      margin: auto;
    }
    .progressRow {
      width: 17rem;
      height: 1.7rem;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      .progressItem {
        width: 13.96rem;
        height: 1.38rem;
      }
      .textNum {
        font-size: 2.92rem;
        font-family: SourceHanSerifCN-Regular, SourceHanSerifCN;
        font-weight: 400;
        color: #ddb883;
        line-height: 4.21rem;
        margin: 0 0.38rem;
      }
    }
  }
  .labelText {
    font-size: 1.85rem;
    font-family: SourceHanSerifCN-SemiBold, SourceHanSerifCN;
    font-weight: 500;
    color: #333333;
    line-height: 3.21rem;
    margin: 0 0 1rem 0;
    padding: 0;
    &.bold {
      font-size: 2.25rem;
      font-weight: 800;
    }

    .titleIcon {
      display: inline-block;
      width: 0.33rem;
      height: 1.67rem;
      background: #ddb883;
      margin-right: 0.67rem;
    }
  }
  .margin6 {
    margin-bottom: 3rem;
  }
  .numDesc {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2.08rem;
    font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
    font-weight: bold;
    color: #ddb883;
    line-height: 3rem;
    margin: 0;
    padding: 0;
    .textNum {
      color: #b08b55;
    }
    &.top,
    &.bottom {
      margin-left: 2rem;
    }
  }
  .middleRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .left,
    .right {
      margin: -4rem 1rem 0;
    }
  }
  .scoreImg {
    width: 17.25rem;
    height: 16.17rem;
    max-width: 100%;
    margin: 0 1rem;
  }
  .contentItem {
    .bar {
      width: 60rem;
      height: 4.58rem;
      background: #ddb883;
      border-radius: 0.83rem 0.83rem 0rem 0rem;
      border: 0.21rem solid #333333;
    }
    .textBox {
      padding: 2rem;
      box-sizing: border-box;
      font-size: 1.42rem;
      font-family: SourceHanSerifCN-Medium, SourceHanSerifCN;
      font-weight: 500;
      color: #333333;
      min-height: 9rem;
      line-height: 2.5rem !important;
      border-radius: 0rem 0rem 0.83rem 0.83rem;
      border: 0.21rem solid #333333;
      border-top: 0;
      height: auto !important;
    }
    .quesImage {
      display: block;
      max-width: 100%;
      margin: 0.5rem auto;
    }
  }
  .subbox {
    margin-bottom: 1rem;
  }
  .subtitle {
    color: #ddb883;
  }
  .message {
    width: 56.25rem;
    font-size: 2.08rem;
    font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
    font-weight: bold;
    color: #333333;
    line-height: 3.33rem;
    min-height: 10.66rem;
    padding: 2rem;
    box-sizing: border-box;
    background: url("../../assets/img/writing/score_num_bg@1x.png") 0 0 no-repeat;
    background-size: 100% 100%;
  }
  .bottomLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .line {
      width: 16.67rem;
      height: 0.04rem;
      border: 0.04rem solid #ddb883;
      margin: 0 3.33rem;
    }
    .lineIcon {
      width: 2.83rem;
      height: 2.83rem;
    }
  }
}
</style>
<style lang="scss">
.writingReportPage {
  .el-progress-bar__outer {
    background: #cacaca;
    border-radius: 0;
  }
  .el-progress-bar__inner {
    border-radius: 0;
  }
}
</style>
